.page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
}
.actionsContainer {
  display: flex;
  height: 36px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.newsType {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.newsType button {
  display: flex;
  height: 28px;
  padding: 7px 10px;
  align-items: center;
  gap: 5px;
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.065px;
  border-radius: 6px;
}
.activeType {
  background: var(--Neutrals-3, #353945);
  color: var(--Neutrals-11-White, var(--White, #fff)) !important;
}
.newsContainer {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--White, #fff);
}
.emptyCurrent ,.emptyArchive{
  display: flex;
  padding: 80px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.emptyCurrent::before,
.emptyArchive::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.65396 11.2761C4 12.5595 4 14.2397 4 17.6V30.4C4 33.7603 4 35.4405 4.65396 36.7239C5.2292 37.8529 6.14708 38.7708 7.27606 39.346C8.55953 40 10.2397 40 13.6 40H34.4C37.7603 40 39.4405 40 40.7239 39.346C41.8529 38.7708 42.7708 37.8529 43.346 36.7239C44 35.4405 44 33.7603 44 30.4V17.6C44 14.2397 44 12.5595 43.346 11.2761C42.7708 10.1471 41.8529 9.2292 40.7239 8.65396C39.4405 8 37.7603 8 34.4 8H13.6C10.2397 8 8.55953 8 7.27606 8.65396C6.14708 9.2292 5.2292 10.1471 4.65396 11.2761ZM12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18H24C25.1046 18 26 17.1046 26 16C26 14.8954 25.1046 14 24 14H12Z' fill='%23959BAB'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 48px;
  height: 48px;
}
.emptyArchive::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'%3E%3Cpath d='M48 6.4002C48 5.5515 47.6629 4.73757 47.0627 4.13745C46.4626 3.53734 45.6487 3.2002 44.8 3.2002H3.2C2.35131 3.2002 1.53737 3.53734 0.937258 4.13745C0.337142 4.73757 0 5.5515 0 6.4002V11.2002C0 12.0489 0.337142 12.8628 0.937258 13.4629C1.53737 14.0631 2.35131 14.4002 3.2 14.4002H44.8C45.6487 14.4002 46.4626 14.0631 47.0627 13.4629C47.6629 12.8628 48 12.0489 48 11.2002V6.4002ZM3.2 41.4082C3.18721 41.8411 3.25985 42.2722 3.41378 42.677C3.5677 43.0818 3.79989 43.4523 4.09707 43.7673C4.39426 44.0823 4.75061 44.3357 5.14576 44.5129C5.5409 44.6902 5.96711 44.7878 6.4 44.8002H41.6C42.4729 44.7709 43.2988 44.3981 43.8981 43.7628C44.4974 43.1275 44.8216 42.2813 44.8 41.4082V17.6002H3.2V41.4082ZM19.2 24.0002H28.8C29.6487 24.0002 30.4626 24.3373 31.0627 24.9375C31.6629 25.5376 32 26.3515 32 27.2002C32 28.0489 31.6629 28.8628 31.0627 29.4629C30.4626 30.0631 29.6487 30.4002 28.8 30.4002H19.2C18.3513 30.4002 17.5374 30.0631 16.9373 29.4629C16.3371 28.8628 16 28.0489 16 27.2002C16 26.3515 16.3371 25.5376 16.9373 24.9375C17.5374 24.3373 18.3513 24.0002 19.2 24.0002Z' fill='%23959BAB'/%3E%3C/svg%3E");
}
.emptyCurrent p,.emptyArchive p {
  color: var(--Neutrals-5, #959bab);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  letter-spacing: -0.075px;
}
.newTableHeader{
  display: flex;
  width: 100%;
}
.newsTableWrapper{
  width: 100%;
}
.newsTableWrapper li:nth-child(1){
  width: 215px;
  flex: none;
}
.newsTableWrapper li:nth-child(3){
  width: 180px;
  flex: none;
}
.newsTableWrapper li:nth-child(4){
  width: 160px;
  flex: none;
}
.newsTableWrapper li:nth-child(5){
  width: 50px;
  flex: none;
}
.newTableHeader li{
  display: flex;
height: 50px;
padding: 15px 10px 15px 20px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 20px;
align-self: stretch;
color: var(--Neutrals-5, #959BAB);
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.065px;
flex: 1;
}
.newsTableBody{
  width: 100%;
  background: var(--Neutrals-9, #F4F5F6);

}
.newsTableBody>li{
  width: inherit !important;
  /* overflow: hidden; */
  position: relative;
  user-select: none;
}
.newsTableRow{
  border-top: 1px solid var(--Neutrals-8, #E6E8EC);
  width: 100%;
  display: flex;
  background: var(--Neutrals-11-White, #FFF);

  cursor: pointer;
}
.newsTableRow li{
  display: flex;
  flex: 1;
height: 70px;
padding: 15px 10px 15px 20px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 3px;
align-self: stretch;
color: var(--Neutrals-3, #353945);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.15px;
position: relative;
}
.newsTableRow li span{
  color: var(--Neutrals-4, #777E90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.065px;
}
.newsTableRow li:last-child::after {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none'%3E%3Cpath d='M3 7L5.64645 4.35355C5.84171 4.15829 5.84171 3.84171 5.64645 3.64644L3 1' stroke='%23353945' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
  width: 8px;
  height: 8px;
  transition: 0.2s;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 20px;
  opacity: 0.5;
}
.newsTableRow:hover li:last-child::after {
  right: 15px;
  opacity: 1;
}

.storyStatus{
  display: flex;
padding: 3px 8px;
justify-content: center;
align-items: center;
gap: 3px;
border-radius: 43px;
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.075px;
}
.storyStatus__UNPUBLISHED{
  background: rgba(235, 143, 37, 0.15);
color: #EB8F25;
}
.storyStatus__PUBLISHED{
  color: var(--Primary-SWiP-Green-Dark, #06B76E);
  background: rgba(0, 140, 79, 0.15);
}
.storyStatus__ARCHIVED{
  background: rgba(119, 126, 144, 0.15);
  color: var(--Neutrals-4, #777E90);
}
.actions{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.actions>div{
  position: relative;
}
.actionsButtonWrapper{
  position: relative;
}
.actionButton{
  display: flex;
  padding: 10px 10px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
}
.actionButton:not([data-black="true"]):hover{
  background: var(--Neutrals-8, #E6E8EC);
}

.actions__dragButton {
  cursor: grab;
}
.actions__dragButton:active {
  cursor: grabbing;
}
.dropdown{
  position: absolute;
  right: 0;
  bottom: -10;
  z-index: 1;
  transform: translateY(100%);
  display: flex;
width: 340px;
min-width: 240px;
padding: 20px;
flex-direction: column;
align-items: flex-start;
gap: 30px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid var(--Neutrals-8, #E6E8EC);
background: var(--Neutrals-11-White, #FFF);

/* Elevation 1 */
box-shadow: 0px 30px 40px 0px rgba(15, 15, 15, 0.10);
}

.dropdownHeader{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.dropdownHeader h4{
  color: var(--Neutrals-3, #353945);
  font-size: 17px;
  font-weight: 590;
  line-height: 18px; /* 105.882% */
}
.dropdownHeader p{
  color: var(--Neutrals-3, #353945);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}
.dropdownActions{
  display: flex;
justify-content: flex-end;
align-items: flex-start;
gap: 10px;
align-self: stretch;
}
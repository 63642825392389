.description {
  display: flex;
  width: 1026px;
  align-items: center;
  gap: 40px;
}
.description img{
  width: 250px;
  height: 160px;
}
  .descriptionTextBlock {
    display: flex;
    padding-right: 100px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
  }
  .descriptionTitle {
    color: var(--Neutrals-1, #141416);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .descriptionText {
    color: var(--Neutrals-4, #777e90);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.065px;
  }
.datePicker{
  display: flex;
padding: 20px;
flex-direction: column;
align-items: flex-end;
gap: 20px;
}
.calendarPresets {
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: flex-start;
}
.calendarPresets li {
  cursor: pointer;
  display: flex;
  height: 36px;
  padding: 10px 12px 10px 40px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}
.activePreset{
  color: var(--Neutrals-1, #141416) !important;
  position: relative;
}
.activePreset::before{
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1007_7787)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 8C0 3.58172 3.58172 0 8 0C12.4182 0 16 3.58172 16 8C16 12.4182 12.4182 16 8 16C3.58172 16 0 12.4182 0 8ZM11.7074 6.70716C12.0979 6.31664 12.0979 5.68344 11.7074 5.2929C11.3168 4.90237 10.6836 4.90237 10.2931 5.2929L7.0001 8.58592L5.70717 7.29298C5.31662 6.90246 4.68344 6.90246 4.2929 7.29298C3.90237 7.68349 3.90237 8.31671 4.2929 8.70722L6.29298 10.7073C6.68349 11.0978 7.31671 11.0978 7.70722 10.7073L11.7074 6.70716Z' fill='%23353945'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1007_7787'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: contain;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.calendarContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  gap: 10px;
}
.calendarInput {
  display: flex;
  height: 36px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.calendarBodyContainer {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.calendarHead {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-self: stretch;
}
.calendarHead button {
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  transition: .2s;
}
.calendarHead button:hover{
  transform: scale(1.1) !important;
}
.calendarHead p {
  color: var(--Neutrals-3, #353945);
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.075px;
}
.calendarBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
}
.calendarBodyRow {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}
.calendarBodyRow span {
  cursor: pointer;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.weekDays span {
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.edgeRange{
  border-radius: 2px;
  background: var(--Neutrals-3, #353945);
  color: var(--Neutrals-10-White, var(--White, #FFF)) !important;
}
.beetweenRange{
  background: var(--Neutrals-8, #E6E8EC);
}
.pickersContainer{
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.calendarsWrapper {
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 20px;
}
.actionContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.calendarsContainer{
  display: flex;
  gap: 20px;
  align-self: stretch;
}
.setTimeContainer{
  display: flex;
justify-content: space-between;
align-items: center;
align-self: stretch;
height: 36px;
}
.setTimeSwitchContainer{
  display: flex;
  align-items: center;
  gap: 8px;
}
.setTimeSwitchContainer p{
  color: var(--Neutrals-3, #353945);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.075px;
}
.setTimeDisplay{
  display: flex;
  align-items: center;
  gap: 10px;
}
.setTimeDisplay p{
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.setTimeDisplay input{
  display: flex;
width: 64px;
height: 36px;
padding: 10px;
align-items: center;
gap: 10px;
color: var(--Neutrals-3, #353945);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 6px;
border: 1px solid var(--Neutrals-8, #E6E8EC);
}
.loadingPage{
    display: flex;
    align-items: center;
    justify-content: center;
}
.loaderWrapper{
    width: 48px;
    height: 48px;
}
.loaderWrapper svg{
    width: inherit;
    height: inherit;
}
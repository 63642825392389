.page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
}
.page .colorInputsContainer * {
  transition: .2s;
}
.page[data-loading="true"] .colorInputsContainer * {
  position: relative;
  color: transparent;
  pointer-events: none;
}
.page[data-loading="true"]
  .colorInputsContainer
  *:not(.colorRGBInputsContainer)::after {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 20%;
  border-radius: 2px;
  background: var(--Neutrals-8, #e6e8ec);
  animation: Skeleton 0.7s linear infinite;
}
.page[data-loading="true"] .colorInputsContainer label:after {
  height: 80% !important; 
  width: 200% !important;
  left: 0 !important;
}
.page[data-loading="true"] .colorInputsContainer .colorInputGroup:after {
  bottom: 7px;
  top: auto;
}

@keyframes Skeleton {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.page hr {
  width: 100%;
  background: #e6e8ec;
  height: 1px;
}

.cardSettingsPreview {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}
.cardSettingsPreview > * {
  flex: 1;
  max-width: 50%;
}
.cardSettingsTitle {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  letter-spacing: -0.075px;
}
.cardSettingsTitleText {
  color: var(--Neutrals-5, #959bab);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.065px;
}
.cardSettingsWrapper {
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;
}
.colorSettings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}
.colorSettingsHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.colorInputsContainer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  width: 100%;
}
.colorRGBInputsContainer {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  flex: 1;
}
.colorRGBInputsContainer input {
  width: 100%;
}
.colorInputGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.colorInputGroup label {
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.colorInputGroup input {
  display: flex;
  height: 38px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--White, #fff);
}
.colorPicker {
  width: 100%;
}
.cardPreviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
}
.cardPreviewCard {
  display: flex;
  padding: 30px 40px 40px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 6px;
  background: var(--White, #fff);

  /* Elevation 1 */
  box-shadow: 0px 30px 40px 0px rgba(15, 15, 15, 0.1);
}
.cardWrapper {
  width: 320px;
  border-radius: 14px;
  overflow: hidden;
}
.cardHeader {
  position: relative;
  height: 165px;
  background-repeat: no-repeat;
  background-size: 100%;

}
.cardMask {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
.cardBalance {
  position: absolute;
  top: 20px;
  left: 20px;
}
.cardBalance p {
  color: var(--White, #fff);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
}
.cardBalance span {
  color: var(--White, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  opacity: 0.65;
  letter-spacing: -0.1px;
}
.merchantName {
  color: var(--White, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 590;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
  display: inline-flex;
  padding: 1px 6px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  border: 1.5px solid var(--White, #fff);
  position: absolute;
  top: 20px;
  right: 20px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break:break-all
}

.cardTextSkeleton {
  width: calc(100% - 40px);
  left: 20px;
  bottom: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 9px;
  position: absolute;
  opacity: 0.25;
}

.cardTextSkeleton::after,
.cardTextSkeleton::before {
  content: "";
  border-radius: 2px;
  height: 100%;
  width: 60px;
  height: 9px;
  flex-shrink: 0;
  background: var(--White, #fff);
}
.cardTextSkeleton::before {
  width: 115px;
}
.cardPhoto {
  height: 122px;
  width: 100%;
}
.cardPhoto img {
  width: 100%;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  height: 100%;
  object-fit: cover;
}

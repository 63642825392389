.page {
  display: flex;
  flex-direction: column;
}
.authContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.authWrapper {
  display: flex;
  width: 360px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.authForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.formFieldContainer {
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 56px;
}
.formField {
  position: absolute;
  top: 0;
  height: inherit;
  left: 0;
  width: 100%;
}
.formField input {
  height: inherit;
  transition: 0.2s;
  width: 100%;
  padding: 26px 20px 11px;
  border-radius: 6px;
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
}
.formField label {
  transition: 0.2s;
  position: absolute;
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.formField input:focus {
  border: 1px solid var(--Neutrals-3, #353945);
}

.formField input:focus + label,
.formField input:not(:placeholder-shown) + label {
  top: 11px;
  transform: translateY(0%);
  font-size: 12px;
}
.formButton {
  display: flex;
  height: 56px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 6px;
  color: var(--Neutrals-8, #fcfcfd);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
  background: var(--Neutrals-3, #353945);

  /* Elevation 3 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.formButton:disabled {
  background: var(--Neutrals-6, #b1b5c3);
}
.formButton:not(:disabled):hover {
  background: var(--Neutrals-1, #141416);
}
.formCheckBoxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.formCheckBoxWrapper span {
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.formCheckBoxWrapper input {
  visibility: hidden;
}
.formCheckBoxWrapper label {
  transition: 0.1s;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='3.5' stroke='%23B1B5C3'/%3E%3C/svg%3E%0A");
}
.formCheckBoxWrapper input:checked + label {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_55_2251)'%3E%3Cpath d='M0 5.68889C0 3.69759 5.29819e-08 2.70194 0.387529 1.94137C0.728418 1.27235 1.27235 0.728418 1.94137 0.387529C2.70194 5.29819e-08 3.69759 0 5.68889 0H10.3111C12.3024 0 13.298 5.29819e-08 14.0587 0.387529C14.7276 0.728418 15.2716 1.27235 15.6124 1.94137C16 2.70194 16 3.69759 16 5.68889V10.3111C16 12.3024 16 13.298 15.6124 14.0587C15.2716 14.7276 14.7276 15.2716 14.0587 15.6124C13.298 16 12.3024 16 10.3111 16H5.68889C3.69759 16 2.70194 16 1.94137 15.6124C1.27235 15.2716 0.728418 14.7276 0.387529 14.0587C5.29819e-08 13.298 0 12.3024 0 10.3111V5.68889Z' fill='%23353945'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.6339 4.61611C13.1221 5.10428 13.1221 5.89576 12.6339 6.38389L7.6339 11.3839C7.14577 11.872 6.35427 11.872 5.86614 11.3839L3.36611 8.8839C2.87796 8.39577 2.87796 7.60427 3.36611 7.11614C3.85428 6.62801 4.64574 6.62801 5.1339 7.11614L6.75002 8.73228L10.8662 4.61611C11.3543 4.12796 12.1458 4.12796 12.6339 4.61611Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_55_2251'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}
.showPassBtn {
  position: absolute;
  width: 24px;
  display: block;
  height: 24px;
  flex-shrink: 0;
  cursor: pointer;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.91406 3.5C4.52358 3.10952 3.89048 3.10952 3.5 3.5C3.10952 3.89048 3.10952 4.52358 3.5 4.91406L4.29397 5.70803C4.71471 6.12878 4.67454 6.8205 4.24382 7.23103C2.15054 9.2262 1.15185 11.4373 1.08008 11.6055C0.973525 11.8557 0.973525 12.1443 1.08008 12.3945C1.21308 12.7055 4.428 20 12 20C13.7554 20 15.2687 19.6027 16.5678 18.9945C16.9716 18.8054 17.4554 18.8695 17.7707 19.1847L19.0859 20.5C19.4764 20.8905 20.1095 20.8905 20.5 20.5C20.8905 20.1095 20.8905 19.4764 20.5 19.0859L4.91406 3.5ZM12 4C11.4068 4 10.843 4.04813 10.3053 4.13426C9.56499 4.25285 9.33985 5.14845 9.87002 5.67861L9.97945 5.78804C10.2135 6.02206 10.5476 6.12124 10.8756 6.07706C11.2366 6.02843 11.6101 6 12 6C16.9081 6 19.645 9.79591 20.6438 11.5419C20.8051 11.8238 20.8035 12.1684 20.6399 12.4489C20.3901 12.8769 20.0324 13.4309 19.5642 14.0228C19.232 14.4428 19.2482 15.0568 19.6268 15.4354C20.0283 15.8369 20.6927 15.832 21.0478 15.389C22.2681 13.8667 22.8644 12.5235 22.9199 12.3945C23.0265 12.1443 23.0265 11.8557 22.9199 11.6055C22.7869 11.2945 19.572 4 12 4ZM5.83804 8.48746C6.21071 8.15119 6.77711 8.19117 7.13205 8.54611L8.01108 9.42514C8.33523 9.7493 8.38397 10.2487 8.22925 10.6802C8.08143 11.0926 8 11.5362 8 12C8 14.206 9.794 16 12 16C12.4638 16 12.9074 15.9186 13.3198 15.7707C13.7513 15.616 14.2507 15.6648 14.5749 15.9889L14.7451 16.1591C15.234 16.648 15.0875 17.4728 14.423 17.6637C13.6823 17.8765 12.8754 18 12 18C7.09188 18 4.35502 14.2041 3.35615 12.4581C3.1949 12.1762 3.19644 11.8316 3.36044 11.5513C3.81734 10.7705 4.63631 9.57179 5.83804 8.48746ZM12.2129 8.02148L15.9785 11.7871C15.8695 9.75411 14.2459 8.13048 12.2129 8.02148ZM10 12C10 11.7694 10.2779 11.6919 10.4409 11.855L12.145 13.5591C12.3081 13.7221 12.2306 14 12 14C10.897 14 10 13.103 10 12Z' fill='%23B1B5C3'/%3E%3C/svg%3E");
}
.showPassBtnShowed {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9999 4C5.14188 4 1.88558 9.87864 1.09558 11.5566C0.963582 11.8366 0.963582 12.1614 1.09558 12.4414C1.88558 14.1204 5.14188 20 11.9999 20C18.8339 20 22.0924 14.1629 22.8964 12.4609C23.0344 12.1679 23.0344 11.8321 22.8964 11.5391C22.0924 9.83706 18.8339 4 11.9999 4ZM11.9999 7C14.7609 7 16.9999 9.239 16.9999 12C16.9999 14.761 14.7609 17 11.9999 17C9.23888 17 6.99988 14.761 6.99988 12C6.99988 9.239 9.23888 7 11.9999 7ZM11.9999 9C11.2042 9 10.4412 9.31607 9.87856 9.87868C9.31595 10.4413 8.99988 11.2044 8.99988 12C8.99988 12.7956 9.31595 13.5587 9.87856 14.1213C10.4412 14.6839 11.2042 15 11.9999 15C12.7955 15 13.5586 14.6839 14.1212 14.1213C14.6838 13.5587 14.9999 12.7956 14.9999 12C14.9999 11.2044 14.6838 10.4413 14.1212 9.87868C13.5586 9.31607 12.7955 9 11.9999 9Z' fill='%23B1B5C3'/%3E%3C/svg%3E%0A");
}
.error {
  display: flex;
  width: 360px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  background: rgba(238, 45, 92, 0.05);
}
.error p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: var(--Seconday-Red, #ee2d5c);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer {
  padding: 32.5px;
  text-align: center;
}
.footer p {
  color: var(--Neutrals-4, #777e90);
  font-size: 12px;
  font-style: normal;
  letter-spacing: 0.05em;
  font-weight: 400;
  line-height: normal;
}

.filterButtonContainer {
  position: relative;
}
.filtersContainer[data-loading='true'] .filterButton{
  pointer-events: none;
  position: relative;
}
.filtersContainer[data-loading='true'] .filterButton *{
  opacity: 0;
}
.filtersContainer[data-loading='true'] .filterButton::after{
  content: '';
  position: absolute;
  width: calc(100% - 20px);
  left: 10px;
  height: 25%;
  border-radius: 2px;
  background: var(--Neutrals-8, #E6E8EC);
}
.filterButton {
  display: flex;
  height: 36px;
  padding: 10px 12px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--White, #fff);
  justify-content: space-between;
  /* Elevation 3 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 5px;
}
.filterButton i {
  height: fit-content;
}
.filterButton svg:not(.strokeFill) path {
  fill: #777e90;
}
.filterButton .strokeFill path {
  stroke: #777e90;
}
.filterButton span {
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}

.filterButton.hasValue {
  border: 1px solid var(--Neutrals-6, #B1B5C3);
  background: var(--Neutrals-7, #D5D8DE);
}
.filterButton.hasValue span {
  color: var(--Neutrals-3, #353945);
}
.filterButton.hasValue svg:not(.strokeFill) path {
  fill: #353945;
}
.filterButton.hasValue .strokeFill path {
  stroke: #353945;
}
.filterButton.opened {
  background: var(--Neutrals-3, #353945);
}
.filterButton.opened span {
  color: var(--Neutrals-10-White, var(--White, #fff));
}
.filterButton.opened svg:not(.strokeFill) path {
  fill: #ffffff;
}
.filterButton.opened .strokeFill path {
  stroke: #ffffff;
}


.filterButtonBody {
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: -10;
  transform: translateY(100%);
  border-radius: 8px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--White, #fff);
  box-shadow: 0px 30px 40px 0px rgba(15, 15, 15, 0.1);
}
.buttonReset,
.buttonConfirm {
  display: flex;
  height: 36px;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--White, #fff);
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.075px;
  /* Elevation 3 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.buttonConfirm {
  border: 1px solid var(--Neutrals-1, #141416);
  background: var(--Neutrals-3, #353945);
  color: var(--Neutrals-10-White, var(--White, #fff));
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.075px;
}

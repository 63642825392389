.page{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 40px;
align-self: stretch;
}
.filtersWrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.filtersContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.filtersContainer *{
  transition: 0.7s opacity;
}
.shortText{
  max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.buttonReset,
.buttonConfirm {
  display: flex;
  height: 36px;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--White, #fff);
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.075px;
  /* Elevation 3 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.buttonConfirm {
  border: 1px solid var(--Neutrals-1, #141416);
  background: var(--Neutrals-3, #353945);
  color: var(--Neutrals-10-White, var(--White, #fff));
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.075px;
}
.resetButton{
  padding: 9px 12px 9px 36px; 
  position: relative;
  font-size: 15px;
  font-weight: 500;
  margin-left: auto;
}
.resetButton::before{
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00024 15.4087C9.01225 15.4087 9.96373 15.215 10.8547 14.8276C11.7505 14.4403 12.5397 13.9052 13.2225 13.2225C13.9052 12.5397 14.4403 11.7529 14.8276 10.8619C15.215 9.96615 15.4087 9.01225 15.4087 8.00024C15.4087 6.98824 15.215 6.03676 14.8276 5.14581C14.4403 4.25002 13.9052 3.46075 13.2225 2.77802C12.5397 2.09528 11.7505 1.56022 10.8547 1.17285C9.95888 0.785482 9.00498 0.591797 7.99298 0.591797C6.98098 0.591797 6.02708 0.785482 5.13129 1.17285C4.24034 1.56022 3.45349 2.09528 2.77075 2.77802C2.09285 3.46075 1.56022 4.25002 1.17285 5.14581C0.785482 6.03676 0.591797 6.98824 0.591797 8.00024C0.591797 9.01225 0.785482 9.96615 1.17285 10.8619C1.56022 11.7529 2.09528 12.5397 2.77802 13.2225C3.46075 13.9052 4.2476 14.4403 5.13855 14.8276C6.03434 15.215 6.98824 15.4087 8.00024 15.4087ZM5.55981 11.0508C5.39518 11.0508 5.25476 10.9927 5.13855 10.8765C5.02234 10.7554 4.96423 10.6126 4.96423 10.4479C4.96423 10.2833 5.02234 10.1429 5.13855 10.0267L7.15771 8.00751L5.13855 5.99561C5.02234 5.87455 4.96423 5.73413 4.96423 5.57434C4.96423 5.40487 5.02234 5.26444 5.13855 5.15308C5.25476 5.03687 5.39518 4.97876 5.55981 4.97876C5.7196 4.97876 5.86003 5.03444 5.98108 5.14581L8.00751 7.16498L10.0485 5.13855C10.1647 5.02234 10.3027 4.96423 10.4625 4.96423C10.6271 4.96423 10.7675 5.02234 10.8837 5.13855C10.9999 5.25476 11.058 5.39518 11.058 5.55981C11.058 5.72445 10.9999 5.86729 10.8837 5.98834L8.8573 8.00751L10.8765 10.0194C10.9927 10.1259 11.0508 10.2688 11.0508 10.4479C11.0508 10.6126 10.9927 10.7554 10.8765 10.8765C10.7603 10.9927 10.6174 11.0508 10.4479 11.0508C10.2736 11.0508 10.1308 10.9927 10.0194 10.8765L8.00751 8.8573L6.00287 10.8765C5.88182 10.9927 5.73413 11.0508 5.55981 11.0508Z' fill='%23353945'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
  position: absolute;

}
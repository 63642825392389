.page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 40px;
  align-self: stretch;
}
.backBtnContainer {
  display: flex;
  height: 44px;
  padding-right: 40px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.backBtn svg {
  height: 14px;
  width: 14px;
}
.backBtn svg path,
.backBtn svg line {
  stroke: #353945;
}
.header {
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  height: 36px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
  transition: 0.2s;
  z-index: 1;
  left: 0;
}
.header.isSticky {
  padding: 20px 0;
  height: 76px;
  background: var(--Neutrals-10, #fcfcfd);

  /* Elevation 2 */
  box-shadow: 0px 0px 10px 0px rgba(15, 15, 15, 0.1);
}
.header.isSticky::after,
.header.isSticky::before {
  content: "";
  height: 100%;
  position: absolute;
  width: 20px;
  background: var(--Neutrals-10, #fcfcfd);
  left: 0;
  top: 0;
  transform: translateX(-100%);
}
.header.isSticky::before {
  right: 0;
  left: auto;
  transform: translateX(100%);
}
.header div {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.header h1 {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.pageContent {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}
.pageContent > * {
  flex: 1;
}

.newsSettingsBody {
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;
}
.newsSettingsBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}
.publicationFragmentHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}
.publicationFragmentHeader h2 {
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.075px;
}
.disabledTitle{
  font-weight: 500 !important;
  color: var(--Neutrals-4, #777E90) !important;

}
.newsSettingsFragment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.newsSettingsFragment h2 {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  letter-spacing: -0.075px;
}
.newsSettingsFragment p {
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.065px;
}
.newsSettingsNewsTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
}
.newsTitleInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
}
.newsTitleInputContainer input,
.newsTitleInputContainer textarea {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--White, #fff);
  resize: none;
}
.photoModes {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.photoModes button {
  display: flex;
  height: 28px;
  padding: 7px 10px;
  align-items: center;
  gap: 5px;
  border-radius: 6px;
  color: var(--Neutrals-4, #777e90);

  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  background: none;
  line-height: normal;
  letter-spacing: -0.065px;
  /* Elevation 3 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.photoModes button.active {
  background: var(--Neutrals-3, #353945);
  color: var(--Neutrals-11-White, var(--White, #fff));
}
.whiteLabelTag {
  width: max-content;
  position: relative;
}
.whiteLabelTag::before {
  content: "White Label";
  position: absolute;
  right: 0;
  display: flex;
  top: 50%;
  transform: translateY(-50%) translateX(calc(100% + 8px));
  height: 21px;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 43px;
  background: rgba(53, 57, 69, 0.1);
  color: var(--Neutrals-3, #353945);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.06px;
}
.NewsItemPrevievWrapper{
  display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
flex: 1 0 0;
position: sticky;
top: 110px;
}
.NewsItemPrevievBody{
  display: flex;
padding: 30px 40px 40px 40px;
flex-direction: column;
align-items: flex-start;
gap: 20px;
border-radius: 6px;
background: var(--White, #FFF);

/* Elevation 1 */
box-shadow: 0px 30px 40px 0px rgba(15, 15, 15, 0.10);

}
.NewsItemPrevievBodyTitle{
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  letter-spacing: -0.075px;
}
.PreviewCard{
  width: 320px;
  border-radius: 14px;
  overflow: hidden;
}
.PreviewCardBody{
  background-color: black;
  padding: 23px;
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 21.267px;
}
.PreviewCardBody h3{
  color: var(--White, #FFF);
  font-size: 21.267px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.819px; /* 112% */
  word-break: break-all;
}
.PreviewCardBody p{
  color: var(--Grey-5, #EAEAEA);
font-size: 13.611px;
font-style: normal;
font-weight: 400;
line-height: 18.715px; /* 137.5% */
word-break: break-all;
letter-spacing: -0.289px;
}
.whiteLabelPreview{
  height: 122px;
  width: 320px;
  overflow: hidden;
}
.mainPage {
  display: flex;
  width: 100%;
}
.pageContent {
  width: 100%;
  padding: 0 20px 20px;
  position: relative;
}
.navigation {
  display: flex;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
  width: 100%;
  margin-top: 25px;
  border-bottom: 1px solid var(--Neutrals-7, #d5d8de);
}
.section {
  min-height: 100%;
}
.navigation li a {
  color: var(--Neutrals-4, #777e90);
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.075px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.navigation li hr {
  background: #141416;
  width: 100%;
  height: 2px;
  transform: translateY(1px);
  opacity: 0;
}
.current a {
  color: var(--Neutrals-1, #141416) !important;
}
.current hr {
  opacity: 1 !important;
}
.page {
  margin: 35px 0;
  position: relative;
  height: 100%;
}

.sectionContent {
  display: flex;
  /* padding: 0px 0px 40px 0px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  align-self: stretch;
}

.title {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pageDescContainer {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px;
}
.pageDescContainer img {
  width: 250px;
  height: 160px;
  flex-shrink: 0;
}
.pageDescTextContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  padding-right: 100px;
}
.pageDescTextContent h2 {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pageDescTextContent p {
  align-self: stretch;
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.065px;
}

.line {
  display: block;
  width: 100%;
  height: 1px;
  background: #e6e8ec;
}
.sectionTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.sectionTitleRow {
  display: flex;
  width: 100%;
  height: 36px;
  justify-content: space-between;
  align-items: center;
}
.sectionTitleRow h2 {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.settingsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.settingsContainerTop {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.settingsContainerTop p {
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.075px;
}
.settingsTableContainer {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--Neutrals-11-White, #fff);
  /* overflow: hidden; */
}
.settingsTable {
  border-collapse: collapse;
  width: 100%;
}
.settingsTable thead span {
  display: flex;
  height: 50px;
  padding: 15px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.settingsTableSubTitle {
  color: var(--Neutrals-5, #959bab);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.065px;
}
.settingsTable tbody tr {
  border-top: 1px solid var(--Neutrals-8, #e6e8ec);
}

.settingsTable tbody p {
  display: flex;
  height: 70px;
  padding: 15px 10px 15px 20px;
  align-items: center;
  gap: 3px;
  align-self: stretch;
}
.settingsTable p {
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}

.tableRowParamsContainer {
  display: flex;
  padding: 15px 10px 15px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.tableRowParamsBlock {
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
}
.tableRowParamsBlock > span {
  display: flex;
  gap: 5px;
  position: relative;
  transition: .2s;
  cursor: pointer;
}
.tableRowParamsBlock > span:hover {
  color: var(--Neutrals-3, #353945);
}
.tableRowParamsBlock > *,
.tableRowParamsBlock button {
  width: 100%;
}
.tableRowParamsBlock button span {
  color: var(--Neutrals-3, #353945);
}
.tableRowParamsHint {
  position: relative;
  cursor: pointer;
}
/* .tableRowParamsHint * {
  color: var(--Neutrals-11-White, #fff) !important;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.065px;
} */

.tableRowAddress {
  display: flex;
  height: 92px;
  padding: 15px 10px 15px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  width: 180px;
  align-self: stretch;
}
.tableRowAddress p {
  padding: 0 !important;
  display: -webkit-box !important;
  height: auto !important;
  -webkit-box-orient: vertical;
  align-items: unset !important;
  -webkit-line-clamp: 2;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--Neutrals-3, #353945);
  word-break: break-all;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}
.tableRowAddress span {
  align-self: stretch;
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.065px;
}

.tableRowParamsInputContainer {
  cursor: pointer;
  display: flex;
  height: 36px;
  padding: 10px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--Neutrals-11-White, #fff);
  transition: 0.2s;
  position: relative;
}
.tableRowParamsInputContainer span {
  color: var(--Neutrals-4, #777e90);
  pointer-events: none;
  user-select: none;
  font-size: 15px;
}
.tableRowParamsInputContainer input {
  color: var(--Neutrals-3, #353945);
  font-size: 15px;
  min-width: 7px !important;
}

.tableRowParamsInputContainer:has(input:focus) {
  border: 1px solid var(--Neutrals-3, #353945);
}
.tableRowParamsInputContainer:has(input[data-error="true"]){
  border: 1px solid var(--Seconday-Red, #EE2D5C);
}

.pickupFormatOptions {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 10px;
  gap: 5px;
}
.pickupFormatOptions button {
  width: auto !important;
  color: var(--Neutrals-3, #353945);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  padding: 5px;
  line-height: normal;
  letter-spacing: 0.15px;
  position: relative;
  display: flex;
  gap: 7px;
  align-items: center;
}
.pickupFormatOptions button::before{
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2625_2109)'%3E%3Crect opacity='0.25' x='1' y='1' width='14' height='14' rx='4' stroke='%23959BAB' stroke-width='2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2625_2109'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  content: "";
  width: 16px;
  display: block;
  height: 16px;
}
.pickupFormatSelectedOpt button::before {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2625_2104)'%3E%3Crect width='16' height='16' rx='5' fill='%23353945'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7074 5.2929C12.0979 5.68344 12.0979 6.31664 11.7074 6.70716L7.70722 10.7073C7.31671 11.0978 6.68349 11.0978 6.29298 10.7073L4.2929 8.70722C3.90237 8.31671 3.90237 7.68349 4.2929 7.29298C4.68344 6.90246 5.31662 6.90246 5.70717 7.29298L7.0001 8.58592L10.2931 5.2929C10.6836 4.90237 11.3168 4.90237 11.7074 5.2929Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2625_2104'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

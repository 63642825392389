.body {
  display: flex;
  min-width: 240px;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  max-height: 500px;
}
.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
}

/* Для WebKit-браузеров (Chrome, Safari) */
.list::-webkit-scrollbar {
  width: 8px; /* Устанавливает ширину скроллбара */
  background: transparent; /* Фон скроллбара делаем прозрачным */
}

.list::-webkit-scrollbar-track {
  border-radius: 20px;
  background: var(--Neutrals-9, #f4f5f6);
}

.list::-webkit-scrollbar-thumb {
  background: var(--Neutrals-5, #959bab);
  border-radius: 11px;
}
.list li,.all {
  cursor: pointer;
  display: flex;
  padding: 9px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
/* .all {
  display: flex;
  height: 55px;
  padding: 10px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
} */
.all span {
  color: var(--Neutrals-4, #777e90) !important;
  font-size: 15px !important;
}
.list li div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.list li p {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}
.list li span {
  color: var(--Neutrals-5, #959bab);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.065px;
}

.list li i {
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_1369_2363)'%3E%3Crect opacity='0.25' x='1' y='1' width='14' height='14' rx='4' stroke='%23959BAB' stroke-width='2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1369_2363'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  width: 16px;
  height: 16px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.selected i {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_1369_2356)'%3E%3Crect width='16' height='16' rx='5' fill='%23353945'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7074 5.2929C12.0979 5.68344 12.0979 6.31664 11.7074 6.70716L7.70722 10.7073C7.31671 11.0978 6.68349 11.0978 6.29298 10.7073L4.2929 8.70722C3.90237 8.31671 3.90237 7.68349 4.2929 7.29298C4.68344 6.90246 5.31662 6.90246 5.70717 7.29298L7.0001 8.58592L10.2931 5.2929C10.6836 4.90237 11.3168 4.90237 11.7074 5.2929Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1369_2356'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") !important;
}
.actionContainer {
  width: 100%;
  display: flex;
  padding: 5px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.actionContainer button {
  flex: 1;
  justify-content: center;
}
